import { render, staticRenderFns } from "./Depoimento.vue?vue&type=template&id=03faa701&scoped=true"
import script from "./Depoimento.vue?vue&type=script&lang=js"
export * from "./Depoimento.vue?vue&type=script&lang=js"
import style0 from "./Depoimento.vue?vue&type=style&index=0&id=03faa701&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03faa701",
  null
  
)

export default component.exports